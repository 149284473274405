import React from 'react';
import '../css/Loading.css';
import spinnerImage from '../images/spinner.png'; // Ensure you have a spinner image in the images folder

const Loading = () => {
  return (
    <div className="loading-container">
      <img src={spinnerImage} alt="Loading..." className="spinner" />
    </div>
  );
}

export default Loading;
