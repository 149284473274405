import React, { useEffect } from 'react';
import '../css/HoeRoscaWerkt.css';
import videoSource from '../images/What is a ROSCA.mp4'; // Importing the video

const HoeRoscaWerkt = () => {
  useEffect(() => {
    const handleScroll = () => {
      const circles = document.querySelectorAll('.circle-pattern');
      const windowHeight = window.innerHeight;

      circles.forEach(circle => {
        const rect = circle.getBoundingClientRect();
        if (rect.top < windowHeight) {
          circle.classList.add('expand');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="section-container-hrw" id="hoe-rosca-werkt">
      <h2><span>Hoe Rosca werkt</span></h2>
      <div className="video-container">
        <video controls>
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="circle-pattern pattern1"></div>
      <div className="circle-pattern pattern2"></div>
    </div>
  );
}

export default HoeRoscaWerkt;
