import React from 'react';
import '../css/RoscaExplain.css';
import icon1 from '../images/icon1.png';
import icon2 from '../images/icon2.png';
import icon3 from '../images/icon3.png';

const RoscaExplain = () => {
  return (
    <div className="rosca-explain-container">
      <div className="section">
        <div className="circlee" style={{ backgroundColor: '#004141' }}>
          <img src={icon3} alt="Icon 3" className="icon" />
        </div>
        <div className="text-content">
          <h2>Rosca's naar uw wensen</h2>
          <p>Er zijn altijd voldoende Rosca Cirkels Blokken beschikbaar om aan de behoeften van elke gebruiker te voldoen. Elke maand worden nieuwe cirkels toegevoegd voor meer opties.</p>
        </div>
      </div>
      <div className="section">
        <div className="circlee" style={{ backgroundColor: '#6ABCEB' }}>
          <img src={icon2} alt="Icon 2" className="icon" />
        </div>
        <div className="text-content">
          <h2>Veilige deelname</h2>
          <p>Alle deelnemers tekenen een contract dat hun rechten en plichten vastlegt, wat extra zekerheid biedt. Daarnaast moeten gebruikers zich identificeren via iDIN (hun bank) en een ID-scan, wat zorgt voor extra veiligheid en betrouwbaarheid.</p>
        </div>
      </div>
      <div className="section">
        <div className="circlee" style={{ backgroundColor: '#8EC200' }}>
          <img src={icon1} alt="Icon 1" className="icon" />
        </div>
        <div className="text-content">
          <h2>Onafhankelijke deelname en betrouwbaarheid</h2>
          <p>De Rosca App automatiseert het proces, zodat u zich geen zorgen hoeft te maken over het verzamelen van mensen of bijdragen. Alzijdigi zorgt ervoor dat elke Rosca Cirkel doorgaat, zelfs bij betalingsuitval van een deelnemer. Dit biedt zekerheid en gemak voor alle gebruikers.</p>
        </div>
      </div>
    </div>
  );
};

export default RoscaExplain;
