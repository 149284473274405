import React, { useEffect, useState } from 'react';
import '../css/Home.css';
import Navbar from './Navbar';
import Benefits from './Benefits'; // Import the Benefits component
import HoeRoscaWerkt from './HoeRoscaWerkt';
import FAQ from './FAQ';
import RoscaExplain from './RoscaExplain';
import RegistratieProcces from './RegistratieProcces';
import Footer from './Footer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import mockupImage from '../images/mockup.png';
import placeholderImage from '../images/placeholder.png';
import downloadDeskImage from '../images/DownloadDesk.png';
import downloadMobileImage from '../images/DownloadMobile.png';

const Home = () => {
  const [displayedText, setDisplayedText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(160);

  const names = ["Rosca", "تندا", "ሱሱ", "جمعية", "Kasmoni"];

  useEffect(() => {
    const handleType = () => {
      const i = loopNum % names.length;
      const fullText = names[i];

      setDisplayedText(
        isDeleting
          ? fullText.substring(0, displayedText.length - 1)
          : fullText.substring(0, displayedText.length + 1)
      );

      setTypingSpeed(isDeleting ? 160 : 160);

      if (!isDeleting && displayedText === fullText) {
        setTimeout(() => setIsDeleting(true), 500);
      } else if (isDeleting && displayedText === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
      }
    };

    const typingInterval = setInterval(handleType, typingSpeed);

    return () => clearInterval(typingInterval);
  }, [displayedText, isDeleting, typingSpeed, loopNum, names]);

  return (
    <div className="home-container" id="home">
      <Navbar />
      <div className="home-content">
        <div className="line">
          <h1>Eerste</h1>
        </div>
        <div className="line dynamic-container">
          <h1 className="dynamic-name">{displayedText}</h1>
        </div>
        <div className="line">
          <h1>App In Nederland!</h1>
        </div>
        <div className="mockup-wrapper">
          <div className="circle"></div>
          <LazyLoadImage
            src={mockupImage}
            alt="App Mockups"
            className="mockup-image"
            placeholderSrc={placeholderImage}
            effect="blur"
          />
        </div>
      </div>
      <Benefits /> {/* Add the Benefits component here */}
      <HoeRoscaWerkt />
      <RoscaExplain />
      <RegistratieProcces />
      <FAQ />
      <div className="download-images">
        <img src={downloadDeskImage} alt="Download for Desktop" className="download-desk-image" />
        <img src={downloadMobileImage} alt="Download for Mobile" className="download-mobile-image" />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
