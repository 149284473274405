import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import '../css/RegistratieProcces.css';
import iDINMockup from '../images/iDINMockup.png';

const RegistratieProcces = () => {
  const [activeStep, setActiveStep] = useState(1);

  const toggleStep = (step) => {
    setActiveStep(step);
  };

  const step1Animation = useSpring({
    height: activeStep === 1 ? 'auto' : 0,
    opacity: activeStep === 1 ? 1 : 0,
    overflow: 'hidden',
  });

  const step2Animation = useSpring({
    height: activeStep === 2 ? 'auto' : 0,
    opacity: activeStep === 2 ? 1 : 0,
    overflow: 'hidden',
  });

  const step3Animation = useSpring({
    height: activeStep === 3 ? 'auto' : 0,
    opacity: activeStep === 3 ? 1 : 0,
    overflow: 'hidden',
  });

  const step4Animation = useSpring({
    height: activeStep === 4 ? 'auto' : 0,
    opacity: activeStep === 4 ? 1 : 0,
    overflow: 'hidden',
  });

  return (
    <div className="registratie-container">
      <h1 className="RPh1">Hoe het registratie proces in zijn werk gaat</h1>
      <div className="content">
        <img src={iDINMockup} alt="iDIN Mockup" className="iDINmockup-image" />
        <div className="steps">
          <div className="step" onClick={() => toggleStep(1)}>
            <div className="step-header">
              <div className="step-number-container">
                <div className="step-number">1</div>
              </div>
              <p className='RPp'>
                Registreren met telefoonnummer
              </p>
            </div>
            <animated.div style={step1Animation}>
            {activeStep === 1 && <div className="step-arrow">&#8595;</div>}

              <div className="step-content">
                Voer je telefoonnummer in en bevestig dit met de ontvangen verificatiecode.
              </div>
            </animated.div>
          </div>
          <div className="step" onClick={() => toggleStep(2)}>
            <div className="step-header">
              <div className="step-number-container">
                <div className="step-number">2</div>
              </div>
              <p className='RPp'>
                Verifiëren Via iDIN
              </p>
            </div>
            <animated.div style={step2Animation}>
            {activeStep === 2 && <div className="step-arrow">&#8595;</div>}
              <div className="step-content">
                Kies je bank en log in via iDIN voor automatische gegevensverificatie.
              </div>
            </animated.div>
          </div>
          <div className="step" onClick={() => toggleStep(3)}>
            <div className="step-header">
              <div className="step-number-container">
                <div className="step-number">3</div>
              </div>
              <p className='RPp'>
                Documenten uploaden
              </p>
            </div>
            <animated.div style={step3Animation}>
            {activeStep === 3 && <div className="step-arrow">&#8595;</div>}
              <div className="step-content">
                Upload de benodigde documenten (ID, bewijs van adres, etc.) voor controle.
              </div>
            </animated.div>
          </div>
          <div className="step" onClick={() => toggleStep(4)}>
            <div className="step-header">
              <div className="step-number-container">
                <div className="step-number">4</div>
              </div>
              <p className='RPp'>
                Account activering
              </p>
            </div>
            <animated.div style={step4Animation}>
              <div className="step-content">
                Na controle van je gegevens door het ROSCA-team, wordt je account geactiveerd en ontvang je een bevestiging.
              </div>
            </animated.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistratieProcces;
