import React, { useState } from 'react';
import '../css/Contact.css';
import Navbar from './Navbar';
import Footer from './Footer';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    telefoon: '',
    subject: '',  // New field for the subject
    message: '',
  });
  const [messageSent, setMessageSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send('service_1wuywzo', 'template_1zol3jj', formData, 'JejbNVGxdQvxQfmbz')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setMessageSent(true);
        setFormData({ name: '', email: '', telefoon: '', subject: '', message: '' }); // Clear form fields
      }, (err) => {
        console.log('FAILED...', err);
      });
  };

  return (
    <div className="contact-container">
      <Navbar />
      <div className="contact-content">
        <h2>Neem Contact met Ons Op</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Naam</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="telefoon">Telefoonnummer</label>
            <input
              type="text"
              id="telefoon"
              name="telefoon"
              value={formData.telefoon}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Onderwerp</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Bericht</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="submit-button">Send</button>
        </form>
        {messageSent && <p style={{ color: 'white', fontSize: '20px', marginTop: '20px' }}>
          Bedankt voor uw bericht. We nemen binnenkort contact met u op.
        </p>}
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
