import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/Navbar.css';
import logo from '../images/logo.png'; // Make sure you have a logo image in the images folder

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    document.body.classList.toggle('menu-open', !isOpen);
  };

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLinkClick = (id) => {
    navigate('/');
    setTimeout(() => handleScroll(id), 100);
    setIsOpen(false);
    document.body.classList.remove('menu-open');
  };

  useEffect(() => {
    // Cleanup body class when component unmounts
    return () => {
      document.body.classList.remove('menu-open');
    };
  }, []);

  return (
    <nav>
      <a href="https://www.rosca.app">
        <img src={logo} alt="Logo" className="logo" />
      </a>
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <ul className={isOpen ? "nav-links open" : "nav-links"}>
        <li><Link className='HoverLink' to="/" onClick={() => handleLinkClick('Download')}>Download App</Link></li>
        <li><Link to="/" onClick={() => handleLinkClick('home')}>Home</Link></li>
        <li><Link to="/" onClick={() => handleLinkClick('hoe-rosca-werkt')}>Hoe Rosca Werkt</Link></li>
        <li><Link to="/" onClick={() => handleLinkClick('faq')}>FAQ's</Link></li>
        <li><Link to="/contact" onClick={() => setIsOpen(false)}>Contact</Link></li>
      </ul>
      <div className={`menu-bg ${isOpen ? 'open' : ''}`} onClick={toggleMenu}></div>
    </nav>
  );
}

export default Navbar;
